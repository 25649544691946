<template>
  <div class="min-h-full">
    <Navigation />
    <NuxtPage />
  </div>
</template>

<script setup>
import Navigation from "~/components/layout/Navigation";
import { useAuthStore } from "~/stores/AuthStore";
import { useHighlightStore } from "~/stores/HighlightStore";
const currentUser = useAuthStore();
const highlights = useHighlightStore();
const route = useRoute();

onMounted(() => {
  currentUser.getUser();
  highlights.getHighlights();
  /*console.log(route);*/
});
</script>

<style></style>
